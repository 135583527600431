<template>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
	<div class="pages-body login-page flex flex-column" style="justify-content: center;"> 
        <div class="grid dashboard">
            <div class="col-12 md:col-12 lg:col-12">
                <h1 class="page-heading">Login As</h1>
                <div class="flex justify-content-center custom-card-container"> 
                    <div class="card overview-box custom-card flex flex-column pt-2 mr-2 " v-for="(role_name,key) in userRoles" :key="key" :style="'background-color:'+  $appState.sideBarColor "> 
                        <div class="flex justify-content-between mt-3 flex-wrap" @click="shiftRole(role_name)" >
                            <div class="flex flex-column "  >
                                <span class="mb-1 fs-xlarge">{{role_name}}</span> 
                            </div> 
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
         
    </div>  
</template> 
<script>

export default {
    data() {
        return { 
            userRoles:[]
        }
    },
    mounted(){ 
        
        if(this.userAccessControles.userAllRolesNames.length > 1){
            this.userRoles = this.userAccessControles.userAllRolesNames;   
        }
        else{
            if(this.$appState.layoutStyle =='mobile'){ 
                this.$router.push('/mobile-view');
            }
            else{ 
                this.$router.push('/dashboard'); 
            } 
        }
    },
    methods:{
        shiftRole(role){  
            localStorage.setItem( 'sessionRole', role);
            if(this.$appState.layoutStyle =='mobile'){
                setTimeout( () => {
                    location.reload();
                },100)
                this.$router.push('/mobile-view'); 
            }
            else{ 
                setTimeout( () => {
                    location.reload();
                },500)
                this.$router.push('/');  
            }  
        }
    }
}
</script>

<style scoped>

</style>
