export default {
    install: (app) => {
        app.mixin({
            data() {
                return {
                    defaultTimeZone: 'Europe/London',
                };
            },
            mounted() {
                Number.prototype.round = function (places) {
                    return +(Math.round(this + "e+" + places) + "e-" + places);
                }
                // this.$emit.on('scaleChanged', (scale) => {
                //     alert(scale);
                // })
            },
            methods: {
                calendarFormat() {

                    var format = 'dd/mm/yy';
                    if (this.userData.company_date_format) {
                        if (this.userData.company_date_format == '31/12/1999') {
                            format = 'dd/mm/yy';
                        } else if (this.userData.company_date_format == '12/31/1999') {
                            format = 'mm/dd/yy';
                        } else if (this.userData.company_date_format == '1999/12/31') {
                            format = 'yy/mm/dd';
                        } else if (this.userData.company_date_format == '1999/31/12') {
                            format = 'yy/dd/mm';
                        } else if (this.userData.company_date_format == '12 Dec, 1999') {
                            format = 'dd M ,yy';
                        } else if (this.userData.company_date_format == '12 December, 1999') {
                            format = 'dd MM ,yy';
                        } else if (this.userData.company_date_format == 'December 12, 1999') {
                            format = 'MM dd,yy';
                        } else if (this.userData.company_date_format == 'Fri 12 Dec, 1999') {
                            format = 'D dd M ,yy';
                        } else if (this.userData.company_date_format == 'Friday 12 Dec, 1999') {
                            format = 'DD dd M ,yy';
                        } else if (this.userData.company_date_format == 'Fri 12 December, 1999') {
                            format = 'D dd MM ,yy';
                        } else if (this.userData.company_date_format == 'Friday 12 December, 1999') {
                            format = 'DD dd MM ,yy';
                        }
                    }
                    return format;
                },
                dateTimeDisplayFormat(displayType) {
                    var format = '';
                    var dateFormat = 'M/D/YYYY';
                    var timeFormat = 'HH:mm:ss';
                    if (this.userData.company_date_format) {
                        if (this.userData.company_date_format == '31/12/1999') {
                            dateFormat = 'D/M/YYYY';
                        } else if (this.userData.company_date_format == '12/31/1999') {
                            dateFormat = 'M/D/YYYY';
                        } else if (this.userData.company_date_format == '1999/12/31') {
                            dateFormat = 'YYYY/M/D';
                        } else if (this.userData.company_date_format == '1999/31/12') {
                            dateFormat = 'YYYY/D/M';
                        } else if (this.userData.company_date_format == '12 Dec, 1999') {
                            dateFormat = 'D MMM ,YYYY';
                        } else if (this.userData.company_date_format == '12 December, 1999') {
                            dateFormat = 'D MMMM ,YYYY';
                        } else if (this.userData.company_date_format == 'December 12, 1999') {
                            dateFormat = 'MMMM D,YYYY';
                        } else if (this.userData.company_date_format == 'Fri 12 Dec, 1999') {
                            dateFormat = 'ddd D MMM ,YYYY';
                        } else if (this.userData.company_date_format == 'Friday 12 Dec, 1999') {
                            dateFormat = 'dddd D MMM ,YYYY';
                        } else if (this.userData.company_date_format == 'Fri 12 December, 1999') {
                            dateFormat = 'ddd D MMMM ,YYYY';
                        } else if (this.userData.company_date_format == 'Friday 12 December, 1999') {
                            dateFormat = 'dddd D MMMM ,YYYY';
                        }
                    }

                    if (this.userData.company_time_format) {
                        //Check time format
                        if (this.userData.company_time_format == '12') {
                            timeFormat = 'hh:mm:ss a';
                        } else if (this.userData.company_time_format == '24') {
                            timeFormat = 'HH:mm:ss';
                        }
                    }

                    if (displayType == 'date') {
                        format = dateFormat;
                    } else if (displayType == 'time') {
                        format = timeFormat;
                    } else if (displayType == 'datetime') {
                        format = dateFormat + ' ' + timeFormat;
                    }

                    return format;
                },
                removeViewFromURL(viewName) {
                    if (!Array.isArray(viewName)) {
                        viewName = [viewName];
                    }

                    let view = this.$route.query.view;
                    if (view) {
                        this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));

                        if (Object.keys(this.urlParams).length) {
                            this.urlParams = this.urlParams.filter((parameter) => {
                                if (viewName.includes(parameter.view)) {
                                    return false;
                                }
                                return true;
                            });
                        }

                        if (Object.keys(this.urlParams).length) {
                            var encrypted = this.$CryptoJS.AES.encrypt(JSON.stringify(this.urlParams), this.encryptKey).toString();
                            this.$router.push({ query: { view: encrypted } });
                        }
                        else {
                            this.$router.push({ query: '' });
                        }
                    }
                },
                addViewInURL(view) {
                    this.urlParams.push(view);
                    var encrypted = this.$CryptoJS.AES.encrypt(JSON.stringify(this.urlParams), this.encryptKey).toString();
                    this.$router.push({ query: { view: encrypted }, params: { savePosition: true } });
                },

                scaleChanged(scale) {
                    // alert(scale);
                },
                printResponseResult(response, alert = true) {
                    // console.log(response);
                    // console.log(response.message);
                    // console.log(response.response);
                    // console.log(response.data);

                    let Vthis = this;
                    let responseStatus = 0;
                    if (response.response) {
                        responseStatus = response.response.status;
                        if (response.response.status == 422 && response.response.data.errors) {
                            let errors = Object.values(response.response.data.errors);
                            errors.forEach((error) => {
                                Vthis.showMessage(error, 'error', 10000);
                            })
                        }
                        else if (response.response.status == 401) {
                            let message = '';
                            if (response.response.data.error) {
                                message = response.response.data.error;
                            }
                            else if (response.response.data.message) {
                                message = response.response.data.message;
                            }

                            Vthis.showMessage(message, 'error', 10000);

                        }
                        else if (response.response.status == 200) {
                            let message = '';
                            if (response.response.data.success) {
                                message = response.response.data.success;
                            }
                            else if (response.response.data.message) {
                                message = response.response.data.message;
                            }
                            if (message == '') {
                                message = 'Action performed Successfully!';
                            }
                            if (alert) {
                                Vthis.showMessage(message, 'success', 10000);
                            }
                        }
                        else if (response.response.status == 500) {
                            let message = response.response.data.message;
                            Vthis.showMessage(message, 'error', 10000);
                        }
                        else if (response.response.status == 400) {
                            let message = response.response.data.message;
                            Vthis.showMessage(message, 'error', 10000);
                        }
                        else if (response.response.status == 404) {
                            let message = 'Invalid request! Page not found.'
                            Vthis.showMessage(message, 'error', 10000);
                        }
                    }
                    else if (response.status) {
                        responseStatus = response.status;
                        let message = '';
                        if (response.message) {
                            message = response.message;
                        }
                        else if (response.data.success) {
                            message = response.data.success;
                        }
                        else if (response.data.error) {
                            message = response.data.error;
                        }
                        else if (response.data.message) {
                            message = response.data.message;
                        }
                        //Check which type message should display error or success 
                        if (response.status == 200) {
                            if (alert) {
                                if (message == '') {
                                    message = 'Action performed Successfully!';
                                }
                                Vthis.showMessage(message, 'success', 10000);
                            }
                        }
                        else if (response.status == 401) {
                            Vthis.showMessage(message, 'error', 10000);
                        }
                        else if (response.status == 400) {
                            Vthis.showMessage(message, 'error', 10000);
                        }
                        else if (response.status == 404) {
                            let message = 'Invalid request! Page not found.'
                            Vthis.showMessage(message, 'error', 10000);
                        }
                    }
                    else if (response.error) {
                        if (response.status) {
                            responseStatus = response.status;
                        }
                        let message = response.error;
                        Vthis.showMessage(message, 'error', 10000);

                    }
                    else if (response.id) {
                        responseStatus = 200;
                        if (alert) {
                            message = 'Action performed Successfully!';
                            Vthis.showMessage(message, 'success', 10000);
                        }
                    } else {
                        // console.log(response);
                        const cookieObj = new URLSearchParams(document.cookie.replaceAll("&", "%26").replaceAll("; ", "&"));
                        if (!cookieObj.get('error')) {
                            this.createCookie('error', response.message, 0.5);
                            responseStatus = 401;
                            let message = 'Some error occured. Please try again later';
                            if (response.response) {
                                if (response.response.message) {
                                    message = response.response.message;
                                }
                            }
                            // Vthis.showMessage(message, 'error', 10000);
                        }
                    }

                    return responseStatus;
                },
                customError(error) {

                    var response = '';
                    if (error.response !== undefined) {
                        if (error.response.data !== undefined) {
                            if (error.response.data.message !== undefined) {
                                if (error.response.data.message) {
                                    response = error.response.data.message;
                                }
                            }
                            else {
                                response = error.response.data;
                            }
                        }
                        else {
                            response = error.response;
                        }
                    } else if (error.request !== undefined) {
                        if (error.request) {
                            response = error.request;
                        }

                    } else if (error.message !== undefined) {
                        if (error.message) {
                            response = error.message;
                        }

                    } else {
                        response = error;
                    }


                    var user_network_error = response.includes("unreachable network");
                    var server_network_error = response.includes("established connection failed");
                    var server_connection_error = response.includes("connection attempt failed");
                    var server_reachability_error = response.includes("network error");
                    var JWT_auth_token_error = response.includes("Lcobucci");
                    if (user_network_error > 0) {
                        response = 'No internet connection!';
                    } else if (server_network_error > 0) {
                        response = 'Web server is unreachable! Please check back shortly';
                    } else if (server_reachability_error > 0 || response == 'network error') {
                        response = 'Network Error! Server is not reachable.';
                    } else if (server_connection_error > 0) {
                        response = 'Web server connectivity problem! Please check back shortly';
                    } else if (JWT_auth_token_error > 0) {
                        response = 'JWT Authuntication failed. Please reload page!';
                    }
                    return response;
                },
                shortName(name) {
                    name = this.removeDoubleSpace(name);
                    var gn = name.split(' ').map(x => x[0]).join('');

                    if (gn.length > 2) {
                        gn = gn[0] + gn[1];
                    }
                    return gn;
                },
                removeDoubleSpace(str) {
                    return str.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
                },
                shortStr(str, start = 0, end = 50) {
                    if (str) {
                        str = str.toString();
                        var eclips = '';

                        if (str.length > end) {
                            eclips = '...';
                        }
                        return str.substr(start, end) + eclips;
                    }

                    return str;

                },
                formatTime(value) {
                    var time = '';
                    if (value) {
                        var timezone = this.defaultTimeZone;
                        if (this.userData.company_timezone) {
                            timezone = this.userData.company_timezone;
                        }
                        time = this.moment(value).tz(timezone).format(this.dateTimeDisplayFormat('time'));
                    }
                    return time;
                },
                formatDate(value) {
                    var date = '';
                    if (value) {
                        var timezone = this.defaultTimeZone;
                        if (this.userData.company_timezone) {
                            timezone = this.userData.company_timezone;
                        }
                        date = this.moment(value).tz(timezone).format(this.dateTimeDisplayFormat('date'));
                    }
                    return date;
                },
                formatDateTime(value) {
                    // console.log(this.dateTimeDisplayFormat('datetime'));
                    var datetime = 'N/A';
                    if (value && value != '1970-01-01 00:00:00' && value != '0000-00-00 00:00:00') {
                        var timezone = this.defaultTimeZone;
                        if (this.userData.company_timezone) {
                            timezone = this.userData.company_timezone;
                        }
                        datetime = this.moment(value).tz(timezone).format(this.dateTimeDisplayFormat('datetime'));
                    }
                    return datetime;
                },
                showMessage(msg, type, time = 5000) {
                    var Vthis = this;
                    return this.$toast.add({
                        severity: type,
                        position: 'top-right',
                        pauseOnHover: true,
                        summary: type.charAt(0).toUpperCase() + type.slice(1) + ' Message!',
                        detail: Vthis.shortStr(Vthis.customError(msg), 0, 100),
                        life: time
                    });
                },
                calculateFileSize(bytes = 0, decimals = 2) {
                    if (bytes === 0) return '0 B';
                    if (!bytes) return '0 B';
                    const kiloByte = 1000;
                    const decimal = decimals < 0 ? 0 : decimals;
                    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                    const i = Math.floor(Math.log(bytes) / Math.log(kiloByte));
                    return `${parseFloat((bytes / kiloByte ** i).toFixed(decimal))} ${sizes[i]}`;
                },
                tableColumnOrderChange(changeEvent, columns) {
                    let newColumnsOrder = [];
                    let draggeAble = columns[changeEvent.dragIndex - 1];
                    columns.forEach((column, key) => {
                        if (key != (changeEvent.dragIndex - 1)) {
                            if (key == (+changeEvent.dropIndex - 1)) {
                                newColumnsOrder.push(draggeAble);
                            }
                            newColumnsOrder.push(column);
                        }
                    })
                    return newColumnsOrder;
                },
                saveTableColumnOrderChange(module_id, columns, selectedColumns) {
                    this.axios.post('saveModuleColumnSettings', { 'module_id': module_id, columns: columns, selectedColumns: selectedColumns })
                        .then(() => {
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                        })
                },

                async getModuleTableSettings(module_id) {

                    let settings = await this.axios.post('getAllModuleColumnSettings', { 'module_id': module_id })
                        .then((response) => {
                            return response.data;
                        })
                        .catch(() => {
                        })
                        .finally(() => {
                        })

                    return settings;
                },
                createCookie(name, value, minutes) {
                    var cookieExpires = '';
                    if (minutes) {
                        var date = new Date();
                        date.setTime(date.getTime() + (minutes * 60 * 1000));
                        cookieExpires = "; expires=" + date.toGMTString();
                    } else {
                        cookieExpires = "";
                    }
                    document.cookie = name + "=" + value + cookieExpires + "; path=/";
                },
                checkPermission(module, permission) {
                    if (this.permissions[module]) {
                        if (this.permissions[module].includes(permission)) {
                            return true;
                        }
                    }
                    return false;
                },
                checkUserCoreRole($roles) {
                    // console.log(this.userAccessControles.userCoreRoles);
                    var roles = [];
                    if (!Array.isArray($roles)) {
                        roles.push($roles);
                    }
                    else {
                        roles = $roles;
                    }
                    var found = false;
                    roles.forEach((role) => {
                        if (this.userAccessControles.userCoreRoles && this.userAccessControles.userCoreRoles.length) {
                            if (this.userAccessControles.userCoreRoles) {
                                if (this.userAccessControles.userCoreRoles.includes(role)) {
                                    if (role == localStorage.getItem('sessionRole')) {
                                        found = true;
                                    }
                                }
                            }
                        }
                    })

                    return found;
                },

                closeCalendar() {

                    this.$refs.calendar1.overlayVisible = false;
                    this.$refs.calendar2.overlayVisible = false;
                    this.$refs.calendar3.overlayVisible = false;
                    this.$refs.calendar4.overlayVisible = false;
                    this.$refs.calendar5.overlayVisible = false;
                    this.$refs.calendar6.overlayVisible = false;

                },

                replaceNullValues(data = {}) {
                    // var filterdData = {};
                    // alert(Object.keys(data).length);
                    if (Object.keys(data).length) {
                        Object.keys(data).filter((dataField) => {
                            if (!data[dataField]) {
                                data[dataField] = '';
                            }
                        })
                    }

                    return data;
                },

            },
        });
    },


};