<template>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
	<div class="pages-body login-page flex flex-column"> 
        <div class="align-self-center mt-auto mb-auto  " style="box-shadow: 1px 2px 125px 20px #efff29d9;">
            <div class="pages-panel card flex flex-column" style="    box-shadow: rgb(50 50 93 / 50%) 0px 50px 100px -20px, rgb(0 0 0 / 45%) 0px 30px 60px -30px, rgb(10 37 64 / 74%) 0px -2px 6px 0px inset;">
                <div class="pages-header px-3 py-1">
                    <h2>{{appName}}</h2>
                </div> 
                <h4>LOGIN</h4> 
                <!-- <div class="pages-detail mb-6 px-10 ">Sign-in</div> -->
                <form @submit.prevent="login" @keydown="form.onKeydown($event)"> 
                    <div v-if="errorMessage" v-html="errorMessage" class="field p-inline-message p-component p-inline-message-error" />
                    <div class="input-panel flex flex-column px-0"> 
                        <div class="field "> 
                            <span class="p-float-label">
                                <InputText type="text" id="email" v-model="form.email" :class="form.errors.has('email') ? 'p-invalid':''" />
                                <label for="email">Email</label>  
                            </span>
                            <HasError class="p-error" :form="form" field="email" />  
                        </div> 
                        <div class="field mb-6 "> 
                            <span class="p-float-label">
                                <InputText type="password" id="password" v-model="form.password" />
                                <label for="password">Password</label> 
                            </span>
                            <HasError class="p-error" :form="form" field="password" /> 
                        </div> 
                    </div> 
                    <Button type="submit" class="mr-2 mb-2 p-button-lg" :disabled="form.busy"  icon="pi pi-sign-in" label="LOGIN" :loading="loading" />
                    <router-link to="/register">
                        <p class="text-center" >Register</p>
                    </router-link>
                </form>
            </div>
        </div>
    </div>  
</template> 
<script>

export default {
    data() {
        return {
            form: new this.Form({ 
                email: '',
                password: '',
            }),
            loading:false,
            errorMessage: '',
            pagePath:document.URL,
        }
    },
    mounted(){ 
        if(localStorage.getItem('token')){
            // localStorage.clear(); 
            this.$router.push('/shift-role');
        }
    },
    methods:{
        async login(){  
            var Vthis = this;
            Vthis.loading = true;
            await this.form
            .post(Vthis.$baseurl+'api/auth/login', Vthis.form)
            .then((response) => { 
                let responseStatus = this.printResponseResult(response); 
                if(responseStatus == 200){
                    // console.log(response.data);   
                    localStorage.setItem( 'userAccessControles', JSON.stringify(response.data.userAccessControles) );
                    localStorage.setItem( 'permissions', JSON.stringify(response.data.permissions) );
                    localStorage.setItem( 'token', response.data.access_token );
                    localStorage.setItem( 'user', JSON.stringify(response.data.user) );
                    localStorage.setItem( 'userSettings', JSON.stringify(response.data.settings) );
                    // console.log(response.data.userAccessControles.userAllRolesNames);
                    // alert(response.data.userAccessControles.userAllRolesNames.length);
                    if(response.data.userAccessControles.userAllRolesNames.length > 1){
                        if(!localStorage.getItem( 'sessionRole')){ 
                            Vthis.$router.go('/shift-role');
                            this.$router.push('/shift-role');
                        } 
                    }
                    else{
                        if(response.data.userAccessControles.userAllRolesNames[0])
                        localStorage.setItem( 'sessionRole', response.data.userAccessControles.userAllRolesNames[0]);
                        Vthis.$router.go('/'); 
                        this.$router.push('/dashboard');
                    }
                    // Vthis.$router.go('/'); 
                }
            })
            .catch((error) => {    
                this.printResponseResult(error);
            })
            .finally(() => {
                Vthis.loading = false;
            }) 
        }
    }
}
</script>

<style scoped>

</style>
