<template>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="pages-body login-page flex flex-column pt-15 mb-15">
        <div class="align-self-center mt-auto mb-auto  " style="box-shadow: 1px 2px 125px 20px #efff29d9;">
            <div class="pages-panel card flex flex-column"
                style="    box-shadow: rgb(50 50 93 / 50%) 0px 50px 100px -20px, rgb(0 0 0 / 45%) 0px 30px 60px -30px, rgb(10 37 64 / 74%) 0px -2px 6px 0px inset;">
                <div class="pages-header px-3 py-1">
                    <h2>{{ appName }}</h2>
                </div>

                <h4>REGISTER</h4>

                <div class="pages-detail mb-6 px-6">Please provide the below details to create an account</div>
                <form method="post">
                    <div class="input-panel flex flex-column px-3">
                        <Message v-if="requestMessage" :severity="requestMessageType">{{ requestMessage }}</Message>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="text" id="name" v-model="form.name" class="full-width" />
                                <label for="name">Name</label>
                            </span>
                        </div>
                        <HasError class="p-error full-width text-left" :form="form" field="name" />

                        <div class="p-inputgroup mt-3">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-building"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="text" id="company" v-model="form.company" class="full-width" />
                                <label for="company">Company</label>
                            </span>
                        </div>
                        <HasError class="p-error full-width text-left" :form="form" field="company" />

                        <div class="p-inputgroup mt-3">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-envelope"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="text" id="email" v-model="form.email" class="full-width" />
                                <label for="email">Email</label>
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3">
                            <!-- <v-phone-input v-model="form.phone" /> -->
                            <vue-tel-input class="full-width" mode="international" style="height:42px;"
                                v-model="phone"></vue-tel-input>
                            <!-- <span class="p-inputgroup-addon">
                                <i class="pi pi-mobile"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="text" id="phone" v-model="form.phone"  class="full-width"/>
                                <label for="phone">Phone (+447975777666)</label>
                            </span>  -->
                        </div>
                        <HasError class="p-error full-width text-left" :form="form" field="phone" />

                        <div class="p-inputgroup mt-3 ">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-lock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="password" id="password" v-model="form.password" class="full-width" />
                                <label for="password">Password</label>
                            </span>
                        </div>
                        <HasError class="p-error full-width text-left" :form="form" field="password" />

                        <div class="p-inputgroup mt-3 mb-6">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-lock"></i>
                            </span>
                            <span class="p-float-label">
                                <InputText type="password" id="password_confirmation" v-model="form.password_confirmation"
                                    class="full-width" />
                                <label for="password_confirmation">Confirm Password</label>
                            </span>
                        </div>
                        <HasError class="p-error full-width text-left" :form="form" field="password_confirmation" />

                    </div>
                    <Button :disabled="form.busy" :loading="loading" @click="register" class="mr-2 mb-2 p-button-lg"
                        icon="pi pi-sign-in" label="Register"></Button>
                    <router-link to="/login">
                        <p class="text-center">Login</p>
                    </router-link>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
// import { HasError } from 'vform'
export default {
    data() {
        return {
            form: new this.Form({
                name: '',
                company: '',
                email: '',
                phone: '',
                password: '',
                password_confirmation: '',
            }),
            phone: '',
            disabled: false,
            loading: false,
            requestMessage: false,
            requestMessageType: null,
        }
    },
    components: {
    },
    mounted() {

        if (localStorage.getItem('token')) {
            this.$router.push('/');
        }

    },
    methods: {
        async register() {
            var Vthis = this;
            Vthis.disabled = true;
            Vthis.loading = true;
            // this.form.fill.phone = document.getElementsByName('telephone')[0].value; 
            this.form.phone = document.getElementsByName('telephone')[0].value;
            await this.form
                .post(Vthis.$baseurl + 'api/auth/register', this.form)
                .then((response) => {

                    let responseStatus = this.printResponseResult(response);
                    if (responseStatus == 200) {
                        // console.log(response.data);   
                        localStorage.setItem('userAccessControles', JSON.stringify(response.data.userAccessControles));
                        localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
                        localStorage.setItem('token', response.data.access_token);
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        localStorage.setItem('userSettings', JSON.stringify(response.data.settings));
                        // console.log(response.data.userAccessControles.userAllRolesNames);
                        // alert(response.data.userAccessControles.userAllRolesNames.length);
                        if (response.data.userAccessControles.userAllRolesNames.length > 1) {
                            // if (!localStorage.getItem('sessionRole')) {
                            if (response.data.userAccessControles.userAllRolesNames[0])
                                localStorage.setItem('sessionRole', response.data.userAccessControles.userAllRolesNames[0]);
                            Vthis.$router.go('/shift-role');
                            // this.$router.push('/shift-role');
                            // }
                        }
                        else {
                            if (response.data.userAccessControles.userAllRolesNames[0])
                                localStorage.setItem('sessionRole', response.data.userAccessControles.userAllRolesNames[0]);
                            // Vthis.$router.go('/'); 
                            // this.$router.push('/dashboard');
                        }
                        Vthis.$router.go('/');
                    }


                    // let responseStatus = this.printResponseResult(response,false); 
                    // if(responseStatus == 200){
                    //     Vthis.form.reset();
                    //     localStorage.setItem( 'permissions', JSON.stringify(response.data.permissions));
                    //     localStorage.setItem( 'token', response.data.access_token );
                    //     localStorage.setItem( 'user', JSON.stringify(response.data.user)); 
                    //     Vthis.requestMessage = 'Account is created successfully';
                    //     Vthis.requestMessageType = 'success'; 
                    //     Vthis.$router.go('/'); 
                    //     Vthis.$router.push('/'); 
                    //     // setTimeout(function(){
                    //     //     Vthis.$router.go('/'); 
                    //     //     Vthis.$router.push('/'); 
                    //     // },1000); 
                    // }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.disabled = false;
                    Vthis.loading = false;
                })
        }
    }
}
</script>

<style scoped></style>
