<template>
  <div v-if="!isFancy">{{ time }}</div> 
  <div v-else>
      <h2>{{ justTime }}</h2>
      <h4>{{ fullDate }}</h4>
  </div> 
</template>

<script> 
export default { 
  data() {
    return { 
      time:'',
      timer: undefined,
      isFancy:false,
      justTime:'',
      fullDate:'',
    };
  },
  props:{
      fancy : {
          type : Boolean,
          required : false,
      } 
  },
  mounted(){
    if(this.fancy){
      this.isFancy = this.fancy;
    } 
    this.moment.locale('en');
  },
  methods: {
    calDateTime(){ 
        this.time =  this.formatDateTime(new Date()); //this.moment(new Date()).format('dddd - DD MMMM, YYYY, h:mm:ss')
        this.justTime =   this.formatTime(new Date()); //this.moment(new Date()).format('h:mm:ss')
        this.fullDate =   this.formatDate(new Date()); //this.moment(new Date()).format('dddd - DD MMMM, YYYY')
    }, 
  },
  beforeMount() {
    this.timer = setInterval(this.calDateTime, 1000); 
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>