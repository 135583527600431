<template>
	<Toast />
	<ConfirmPopup></ConfirmPopup>
	<div :class="[layoutContainerClass]" @click="onDocumentClick">
		<AppTopBar :horizontal="d_menuMode === 'horizontal'" :topbarMenuActive="topbarMenuActive"
			:activeTopbarItem="activeTopbarItem" :mobileTopbarActive="mobileTopbarActive"
			@topbar-mobileactive="onTopbarMobileButtonClick" @menubutton-click="onMenuButtonClick"
			@topbar-menubutton-click="onTopbarMenuButtonClick" @topbaritem-click="onTopbarItemClick"
			@rightpanel-button-click="onRightPanelButtonClick" :searchActive="searchActive" @search-toggle="onSearchToggle"
			@search-click="onSearchClick" @search-hide="onSearchHide"></AppTopBar>

		<div class="menu-wrapper" v-if="$appState.layoutStyle != 'mobile'">
			<div class="layout-menu-container" @click="onMenuClick">
				<AppInlineMenu v-if="inlineMenuPosition === 'top' || inlineMenuPosition === 'both'"
					v-model:active="inlineMenuTopActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="top"
					:menuMode="menuMode"></AppInlineMenu>
				<AppMenu :model="menu" :d_menuMode="menuMode" :active="menuActive" :mobileMenuActive="mobileMenuActive"
					:isSlimOrHorItemClick="isSlimOrHorItemClick" @menuitem-click="onMenuItemClick"
					@root-menuitem-click="onRootMenuItemClick"></AppMenu>
				<AppInlineMenu v-if="inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both'"
					v-model:active="inlineMenuBottomActive" @change-inlinemenu="onChangeInlineMenu" inlineMenuKey="bottom"
					:menuMode="menuMode"></AppInlineMenu>
			</div>
		</div>

		<div class="layout-main" :class="$appState.layoutStyle == 'mobile' ? 'app-view' : ''">

			<AppBreadcrumb></AppBreadcrumb>

			<div class="layout-content">
				<router-view />
			</div>
			<!-- <AppFooter :layoutMode="layoutMode" /> -->
		</div>
		<AppMobileViewMenu v-if="$appState.layoutStyle == 'mobile'" />
		<AppConfig :d_menuMode="menuMode" @menu-mode-change="onMenuModeChange" @layout-mode-change="onLayoutModeChange"
			@menu-theme="onMenuTheme" :layoutMode="d_layoutMode" @topbar-theme="onTopbarThemeChange"
			v-model:inlineMenuPosition="inlineMenuPosition" @inlinemenu-change="onInlineMenuPositionChange" :theme="theme"
			:themes="themes" @theme-change="changeTheme" :menuTheme="d_menuTheme" :menuThemes="menuThemes"
			:topbarTheme="d_topbarTheme" :topbarThemes="topbarThemes" />

		<!-- <AppRightPanel :expanded="rightPanelActive" @content-click="onRightPanelClick" @hide="onHideClick"></AppRightPanel> -->
		<div v-if="mobileMenuActive" class="layout-mask modal-in"></div>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppInlineMenu from './AppInlineMenu.vue';
// import AppRightPanel from './AppRightPanel.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import AppMobileViewMenu from './AppMobileViewMenu.vue';
// import AppFooter from './AppFooter.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import EventBus from './event-bus';
export default {
	emits: ['layout-mode-change', 'menu-theme', 'menuTheme', 'topbar-theme', 'topbarTheme', 'layoutModeChange'],
	props: {
		topbarTheme: String,
		menuTheme: String,
		layoutMode: String,
		menuMode: String,
	},
	data() {
		return {
			d_topbarTheme: this.topbarTheme,
			d_menuTheme: this.menuTheme,
			d_layoutMode: this.layoutMode,
			d_menuMode: this.menuMode,
			mobileTopbarActive: false,
			mobileMenuActive: false,
			search: false,
			searchClick: false,
			searchActive: false,
			inlineMenuClick: false,
			inlineMenuPosition: 'bottom',
			inlineMenuTopActive: false,
			inlineMenuBottomActive: false,
			overlayMenuActive: false,
			rotateMenuButton: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			isSlimOrHorItemClick: false,
			darkMenu: false,
			theme: 'blue',
			themes: [
				{ name: 'indigo', color: '#2f8ee5' },
				{ name: 'pink', color: '#E91E63' },
				{ name: 'purple', color: '#9C27B0' },
				{ name: 'deeppurple', color: '#673AB7' },
				{ name: 'blue', color: '#2196F3' },
				{ name: 'lightblue', color: '#03A9F4' },
				{ name: 'cyan', color: '#00BCD4' },
				{ name: 'teal', color: '#009688' },
				{ name: 'green', color: '#4CAF50' },
				{ name: 'lightgreen', color: '#8BC34A' },
				{ name: 'lime', color: '#CDDC39' },
				{ name: 'yellow', color: '#FFEB3B' },
				{ name: 'amber', color: '#FFC107' },
				{ name: 'orange', color: '#FF9800' },
				{ name: 'deeporange', color: '#FF5722' },
				{ name: 'brown', color: '#795548' },
				{ name: 'bluegrey', color: '#607D8B' }
			],
			menuThemes: [
				{ name: 'light', color: '#FDFEFF' },
				{ name: 'dark', color: '#434B54' },
				{ name: 'indigo', color: '#1A237E' },
				{ name: 'bluegrey', color: '#37474F' },
				{ name: 'brown', color: '#4E342E' },
				{ name: 'cyan', color: '#006064' },
				{ name: 'green', color: '#2E7D32' },
				{ name: 'deeppurple', color: '#4527A0' },
				{ name: 'deeporange', color: '#BF360C' },
				{ name: 'pink', color: '#880E4F' },
				{ name: 'purple', color: '#6A1B9A' },
				{ name: 'teal', color: '#00695C' },
			],
			topbarThemes: [
				{ name: 'lightblue', color: '#2E88FF' },
				{ name: 'dark', color: '#363636' },
				{ name: 'white', color: '#FDFEFF' },
				{ name: 'blue', color: '#1565C0' },
				{ name: 'deeppurple', color: '#4527A0' },
				{ name: 'purple', color: '#6A1B9A' },
				{ name: 'pink', color: '#AD1457' },
				{ name: 'cyan', color: '#0097A7' },
				{ name: 'teal', color: '#00796B' },
				{ name: 'green', color: '#43A047' },
				{ name: 'lightgreen', color: '#689F38' },
				{ name: 'lime', color: '#AFB42B' },
				{ name: 'yellow', color: '#FBC02D' },
				{ name: 'amber', color: '#FFA000' },
				{ name: 'orange', color: '#FB8C00' },
				{ name: 'deeporange', color: '#D84315' },
				{ name: 'brown', color: '#5D4037' },
				{ name: 'grey', color: '#616161' },
				{ name: 'bluegrey', color: '#546E7A' },
				{ name: 'indigo', color: '#3F51B5' }
			],
			rightPanelActive: false,
			menuActive: true,
			menu: [],
			QuickLinks: [],
			// defaultPage : '/access/modules'
		}
	},
	mounted() {

		var modules = [];
		var modulesOrder = [];
		var sessionRole = localStorage.getItem('sessionRole');
		// console.log(this.permissions); 
		if (this.permissions && sessionRole) {
			modules = [];
			var modulesIds = Object.keys(this.permissions);
			// console.log(modules);
			modulesIds.forEach((moduleid) => {
				if (!(this.permissions[moduleid].length < 3 && this.permissions[moduleid][1] == "dropdown")) {
					modulesOrder.push({ moduleId: parseInt(moduleid), order: this.permissions[moduleid][0].order });
					modules.push(parseInt(moduleid));
				}
			});

			// modules = Object.keys(modulesOrder); 
			// console.log(modulesOrder);
		}

		this.QuickLinks = {
			label: 'Quick Links',
			icon: 'pi pi-fw pi-plus',
			items: []
		};

		var getQuickLinks = this.getQuickLinks();


		if (modules.length > 0) {
			var AccessManagement = {
				label: 'Access Management',
				icon: 'pi pi-fw pi-plus',
				items: []
			};


			if (modules.includes(2) || modules.includes(3)) {
				AccessManagement.items = [this.getMenuItem('Module Management', 'pi pi-fw pi-plus', '', [])];
			}
			var Companies = {
				label: 'Companies Management',
				icon: 'pi pi-fw pi-building',
				items: []
			};
			if (modules.includes(7) || modules.includes(8)) {
				Companies.items = [this.getMenuItem('Manage Company', 'pi pi-fw pi-building', '', [])];
			}
			var Vehicles = {
				label: 'Vehicles Management',
				icon: 'pi pi-fw pi-car',
				items: []
			};
			var Products = {
				label: 'Products Management',
				icon: 'pi pi-fw pi-list',
				items: []
			};
			var FrontEnd = {
				label: 'Front End Management',
				icon: 'pi pi-fw pi-list',
				items: []
			};

			// var Customers = {
			// 	label: 'Customers Management', 
			// 	icon: 'pi pi-fw pi-users',
			// 	items:[]
			// };
			var Suppliers = {
				label: 'Suppliers Management',
				icon: 'pi pi-fw pi-microsoft',
				items: []
			};
			var Orders = {
				label: 'Orders Management',
				icon: 'pi pi-fw pi-box',
				items: []
			};
			var Dashboards = {
				label: 'Dashboards Management',
				icon: 'pi pi-fw pi-palette',
				items: []
			};
			var Calendar = {
				label: 'Calendar Management',
				icon: 'pi pi-fw pi-calendar',
				items: []
			};

			var SystemSettings = {
				label: 'System Settings',
				icon: 'pi pi-cog pi-fw',
				items: []
			};

			var MyDrive = {
				label: 'My Drive',
				icon: 'pi pi-file pi-fw',
				items: []
			};




			modulesOrder.forEach((moduleData) => {

				switch (moduleData.moduleId) {
					case 2:
						// console.log(AccessManagement.items);
						AccessManagement.items.filter(item => {
							if (item.label == 'Module Management') {
								if (item.items)
									item.items.push(this.getMenuItem('Modules', 'pi pi-fw pi-plus', '/access/modules', false, moduleData.order));
							}
							return item;
						})
						break;
					case 3:
						AccessManagement.items.filter(item => {
							if (item.label == 'Module Management') {
								if (item.items)
									item.items.push(this.getMenuItem('Modules Permissions', 'pi pi-fw pi-plus', '/access/module-permissions', false, moduleData.order));
							}
							return item;
						})
						break;
					case 4:
						AccessManagement.items.push(this.getMenuItem('Roles', 'pi pi-fw pi-plus', '/access/roles', false, moduleData.order));
						break;
					case 12:
						AccessManagement.items.push(this.getMenuItem('Access Levels', 'pi pi-fw pi-plus', '/access/levels', false, moduleData.order));
						break;
					case 5:
						AccessManagement.items.push(this.getMenuItem('Users', 'pi pi-fw pi-users', '/access/manage-users', false, moduleData.order));
						break;
					case 64:
						AccessManagement.items.push(this.getMenuItem('User Categories', 'pi pi-fw pi-users', '/access/manage-users-categories', false, moduleData.order));
						break;
					case 16:
						Companies.items.filter(item => {
							if (item.label == 'Manage Company') {
								if (item.items)
									item.items.push(this.getMenuItem('Companies', 'pi pi-fw pi-building', '/companies/manage-companies', false, moduleData.order));
							}
							return item;
						})
						break;
					case 7:
						Companies.items.filter(item => {
							if (item.label == 'Manage Company') {
								if (item.items)
									item.items.push(this.getMenuItem('Subsidiaries', 'pi pi-fw pi-building', '/companies/manage-subsidiaries', false, moduleData.order));
							}
							return item;
						})
						break;
					case 8:
						Companies.items.filter(item => {
							if (item.label == 'Manage Company') {
								if (item.items)
									item.items.push(this.getMenuItem('Branches', 'pi pi-fw pi-building', '/companies/manage-branches', false, moduleData.order));
							}
							return item;
						})
						break;

					case 9:
						Companies.items.push(this.getMenuItem('Departments', 'pi pi-fw pi-building', '/companies/manage-departments', false, moduleData.order));
						break;
					case 11:
						Companies.items.push(this.getMenuItem('Designations', 'pi pi-fw pi-building', '/companies/manage-designations', false, moduleData.order));
						break;
					case 18:
						Companies.items.push(this.getMenuItem('Canceled Reasons', 'pi pi-fw pi-building', '/companies/manage-canceled-reasons', false, moduleData.order));
						break;
					case 19:
						Companies.items.push(this.getMenuItem('Journey Types', 'pi pi-fw pi-building', '/companies/manage-journey-types', false, moduleData.order));
						break;
					case 21:
						Vehicles.items.push(this.getMenuItem('Vehicle Type Groups', 'pi pi-fw pi-car', '/vehicles/manage-vehicle-type-groups', false, moduleData.order));
						break;
					case 22:
						Vehicles.items.push(this.getMenuItem('Vehicle Tax Rates', 'pi pi-fw pi-car', '/vehicles/manage-vehicle-tax-rates', false, moduleData.order));
						break;
					case 23:
						Vehicles.items.push(this.getMenuItem('Cargo Groups', 'pi pi-fw pi-car', '/vehicles/manage-vehicle-cargo-groups', false, moduleData.order));
						break;
					case 24:
						Vehicles.items.push(this.getMenuItem('Cargo Manifests', 'pi pi-fw pi-car', '/vehicles/manage-vehicle-cargo-manifests', false, moduleData.order));
						break;
					case 25:
						Vehicles.items.push(this.getMenuItem('Cargo Types', 'pi pi-fw pi-car', '/vehicles/manage-vehicle-cargo-types', false, moduleData.order));
						break;
					case 27:
						Products.items.push(this.getMenuItem('Products', 'pi pi-fw pi-list', '/products/manage-products', false, moduleData.order));
						break;
					case 28:
						Products.items.push(this.getMenuItem('Product Categories', 'pi pi-fw pi-list', '/products/manage-product-categories', false, moduleData.order));
						break;
					// case 30 : 
					// 	Customers.items.push(this.getMenuItem('Customer Categories','pi pi-fw pi-users','/customers/manage-customer-categories',false,moduleData.order)); 
					//     break;
					// case 31 : 
					// 	Customers.items.push(this.getMenuItem('Customers','pi pi-fw pi-users','/customers/manage-customers',false,moduleData.order)); 
					//     break;
					case 33:
						Suppliers.items.push(this.getMenuItem('Supplier Categories', 'pi pi-fw pi-microsoft', '/suppliers/manage-supplier-categories', false, moduleData.order));
						break;
					case 34:
						Suppliers.items.push(this.getMenuItem('Document Types', 'pi pi-fw pi-microsoft', '/suppliers/manage-supplier-document-types', false, moduleData.order));
						break;
					case 35:
						Suppliers.items.push(this.getMenuItem('Suppliers', 'pi pi-fw pi-microsoft', '/suppliers/manage-suppliers', false, moduleData.order));
						break;
					case 36:
						Vehicles.items.push(this.getMenuItem('Vehicle Types', 'pi pi-fw pi-car', '/vehicles/manage-vehicle-types', false, moduleData.order));
						break;
					case 38:
						Vehicles.items.push(this.getMenuItem('Vehicles', 'pi pi-fw pi-car', '/vehicles/manage-vehicles', false, moduleData.order));
						break;
					case 40:
						Orders.items.push(this.getMenuItem('Orders', 'pi pi-fw pi-ticket', '/orders/manage-orders', false, moduleData.order));
						break;
					case 49:
						Dashboards.items.push(this.getMenuItem('Dashboard', 'pi pi-chart-line', '/dashboard', false, moduleData.order));
						break;
					case 50:
						Calendar.items.push(this.getMenuItem('Calendar', 'pi pi-calendar', '/calendar', false, moduleData.order));
						break;
					case 56:
						SystemSettings.items.push(this.getMenuItem('Automation Settings', 'pi pi-spin pi-spinner', '/automation-settings', false, moduleData.order));
						break;
					case 57:
						SystemSettings.items.push(this.getMenuItem('Email Templates', 'pi pi-envelope', '/email-templates', false, moduleData.order));
						break;
					case 59:
						MyDrive.items.push(this.getMenuItem('My Drive', 'pi pi-file', '/my-drive', false, moduleData.order));
						break;
					case 61:
						MyDrive.items.push(this.getMenuItem('File Categories', 'pi pi-file', '/file-categories', false, moduleData.order));
						break;
					case 62:
						SystemSettings.items.push(this.getMenuItem('Pricing Rules', 'pi pi-dollar', '/pricing-rules', false, moduleData.order));
						break;
					case 63:
						SystemSettings.items.push(this.getMenuItem('Payment Gateways', 'pi pi-dollar', '/payment-gateways', false, moduleData.order));
						break;
					case 66:
						Orders.items.push(this.getMenuItem('Order Categories', 'pi pi-fw pi-ticket', '/orders/order-categories', false, moduleData.order));
						break;
					case 67:
						FrontEnd.items.push(this.getMenuItem('Reviews', 'pi pi-fw pi-ticket', '/front-end-management/reviews', false, moduleData.order));
						break;
					case 69:
						FrontEnd.items.push(this.getMenuItem('Client Companies', 'pi pi-fw pi-ticket', '/front-end-management/client-companies', false, moduleData.order));
						break;
				}
			});

			var menu = [];

			if (Dashboards.items.length) {
				menu.push(Dashboards);
			}
			if (Calendar.items.length) {
				menu.push(Calendar);
			}
			if (AccessManagement.items.length) {
				menu.push(AccessManagement);
			}
			if (Companies.items.length) {
				menu.push(Companies);
			}
			if (Vehicles.items.length) {
				menu.push(Vehicles);
			}
			if (Products.items.length) {
				menu.push(Products);
			}

			// if(Customers.items.length){
			// 	menu.push(Customers);
			// } 
			if (Suppliers.items.length) {
				menu.push(Suppliers);
			}
			if (Orders.items.length) {
				menu.push(Orders);
			}
			if (SystemSettings.items.length) {
				menu.push(SystemSettings);
			}
			if (MyDrive.items.length) {
				menu.push(MyDrive);
			}
			if (FrontEnd.items.length) {
				menu.push(FrontEnd);
			}

			menu.forEach((menuItems) => {
				if (menuItems.items.length) {
					menuItems.items = menuItems.items.sort((a, b) => {
						if (a.order < b.order) {
							return -1;
						}
						if (a.order > b.order) {
							return 1;
						}
						return 0;
					});

					menuItems.items.forEach((menuSubItems) => {
						if (menuSubItems.items) {
							if (menuSubItems.items.length) {
								menuSubItems.items = menuSubItems.items.sort((a, b) => {
									if (a.order < b.order) {
										return -1;
									}
									if (a.order > b.order) {
										return 1;
									}
									return 0;
								});
							}
						}
					});
				}
			});

			getQuickLinks.then(() => {
				if (this.QuickLinks.items.length) {
					menu.unshift(this.QuickLinks);
				}
				this.menu = menu;
			})
			// console.log(this.menu);
		}

		if (!localStorage.getItem('token')) {
			this.$router.push('/login');
		}
		else {
			if (!localStorage.getItem('sessionRole')) {
				this.$router.push('/shift-role');
			}
			else {
				// this.$router.push('/login');
			}
		}
	},
	watch: {

		$route() {
			this.menuActive = this.isStatic() && !this.isMobile();
			this.$toast.removeAllGroups();
		},
		topbarTheme(newValue) {
			this.d_topbarTheme = newValue;
		},
		menuTheme(newValue) {
			this.d_menuTheme = newValue;
		},
		layoutMode(newValue) {
			this.d_layoutMode = newValue;
		}
	},
	methods: {

		getQuickLinks() {
			var Vthis = this;
			return this.axios.post('getAllQuickLinks')
				.then((response) => {
					// this.quickLinks = response.data;  
					let responseStatus = this.printResponseResult(response, false);
					if (responseStatus == 200) {
						if (response.data) {
							response.data.forEach((rec) => {
								// Vthis.quickLinks[0].items[0][0].items.push({ 'label': rec.name, 'icon': 'pi pi-fw pi-link', 'to': rec.link  }); 
								Vthis.QuickLinks.items.push(this.getMenuItem(rec.name, 'pi pi-fw pi-plus', rec.link, false, rec.sort_order, rec.open_in_new_window ? 'blank' : '', true));
							})
						}
					}

					console.log(Vthis.QuickLinks);
				})
				.catch((error) => {
					this.printResponseResult(error);
				})
				.finally(() => {
				})
		},

		getMenuItem(label, icon, to, items = false, sort_order = 1, target = '', quickLink = false) {
			var obj = {
				quickLink: quickLink,
				target: target,
				label: label,
				icon: icon,
				to: to,
				order: sort_order,
				meta: {
					breadcrumb: [{ parent: 'UI Kit', label: 'Form Layout' }],
				}
			}
			if (items) {
				obj.items = [];
			}
			return obj;
		},
		onDocumentClick() {
			if (!this.searchClick && this.searchActive) {
				this.onSearchHide();
			}

			if (!this.topbarItemClick) {
				this.activeTopbarItem = null;
				this.topbarMenuActive = false;
			}

			if (!this.menuClick) {
				if (this.isHorizontal() || this.isSlim()) {
					this.menuActive = false;
					this.isSlimOrHorItemClick = false;
					EventBus.emit('reset-active-index');
				}

				if (this.isOverlay()) {
					this.menuActive = false;
				}

				this.hideOverlayMenu();
				this.unblockBodyScroll();
			}

			if (!this.rightPanelClick) {
				this.rightPanelActive = false;
			}

			if (!this.inlineMenuClick) {
				this.inlineMenuTopActive = false;
				this.inlineMenuBottomActive = false;
			}

			this.topbarItemClick = false;
			this.menuClick = false;
			this.rightPanelClick = false;
			this.searchClick = false;
			this.inlineMenuClick = false;
		},
		onSearchToggle() {
			this.searchActive = !this.searchActive;
			this.searchClick = true;
		},
		onSearchClick() {
			this.searchClick = true;
		},
		onSearchHide() {
			this.searchActive = false;
			this.searchClick = false;
		},
		isHorizontal() {
			return this.d_menuMode === 'horizontal';
		},
		isSlim() {
			return this.d_menuMode === 'slim';
		},
		isOverlay() {
			return this.d_menuMode === 'overlay';
		},
		isStatic() {
			return this.d_menuMode === 'static';
		},
		isDesktop() {
			return window.innerWidth > 991;
		},
		isMobile() {
			return window.innerWidth <= 991;
		},
		hideOverlayMenu() {
			this.rotateMenuButton = false;
			this.overlayMenuActive = false;
			this.mobileMenuActive = false;
		},
		onMenuButtonClick(event) {
			this.menuClick = true;
			this.menuActive = !this.menuActive;
			this.topbarMenuActive = false;
			this.topbarRightClick = true;
			this.rotateMenuButton = !this.rotateMenuButton;
			this.topbarMenuActive = false;

			if (!this.isDesktop()) {
				this.mobileMenuActive = !this.mobileMenuActive;
				if (this.mobileMenuActive) {
					this.blockBodyScroll();
				} else {
					this.unblockBodyScroll();
				}
			}

			event.preventDefault();
		},
		onTopbarMenuButtonClick(event) {
			this.topbarItemClick = true;
			this.topbarMenuActive = !this.topbarMenuActive;
			this.hideOverlayMenu();
			event.preventDefault();
		},
		onTopbarItemClick(event) {
			this.topbarItemClick = true;

			if (this.activeTopbarItem === event.item)
				this.activeTopbarItem = null;
			else
				this.activeTopbarItem = event.item;

			event.originalEvent.preventDefault();
		},
		onTopbarMobileButtonClick(event) {
			this.mobileTopbarActive = !this.mobileTopbarActive;
			event.preventDefault();
		},
		onRightPanelButtonClick(event) {
			this.rightPanelClick = true;
			this.rightPanelActive = !this.rightPanelActive;
			event.preventDefault();
		},
		onRightPanelClick() {
			this.rightPanelClick = true;
		},
		onHideClick(expanded) {
			this.rightPanelActive = expanded;
		},
		onMenuClick() {
			this.menuClick = true;
		},
		onRootMenuItemClick(event) {
			if (event.isSameIndex) {
				this.isSlimOrHorItemClick = false;
			}
			else {
				this.isSlimOrHorItemClick = true;
			}
			this.menuActive = !this.menuActive;
		},
		isURL(str) {
			var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
			return pattern.test(str);
		},
		onMenuItemClick(event) {

			if (event.item.quickLink) {
				if (event.item.target == 'blank') {
					window.open(event.item.to, '_blank');
				}
				else if (this.isURL(event.item.to)) {
					// window.open(event.item.to);
					location.replace(event.item.to);
				}
				else {
					var link = event.item.to.replace('/#/', '');
					EventBus.emit('reset-active-index');
					this.$router.push('/dashboard');
					location.replace(this.$weburl + '#/' + link);
					// window.history.replaceState({}, document.title, window.location.origin + '/#');
					// this.$router.push('/dashboard');
					// this.$router.push({ path: link });
					// this.$router.push(this.$weburl+'#/'+link);
				}
			}
			if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
				this.menuActive = false;
			}

			if (!event.item.items) {
				this.isSlimOrHorItemClick = false;
				this.hideOverlayMenu();
				EventBus.emit('reset-active-index');
			}

		},
		onMenuModeChange(menuMode) {
			this.d_menuMode = menuMode;
			this.overlayMenuActive = false;

			if (menuMode === 'static') {
				this.menuActive = true;
			}

			if (menuMode === 'horizontal') {
				this.inlineMenuPosition = 'bottom';
			}
		},
		onLayoutModeChange(menuColor) {
			this.$emit('layout-mode-change', menuColor);

			const layoutLink = document.getElementById('layout-css');
			const layoutHref = 'layout/css/layout-' + menuColor + '.css';
			this.replaceLink(layoutLink, layoutHref);

			const themeLink = document.getElementById('theme-css');
			const urlTokens = themeLink.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 1] = 'theme-' + menuColor + '.css';
			const newURL = urlTokens.join('/');

			this.replaceLink(themeLink, newURL, () => {
				this.$appState.isNewThemeLoaded = true;
			});
		},
		onInlineMenuPositionChange(position) {
			this.inlineMenuPosition = position;
		},
		onChangeInlineMenu(e, key) {
			if (key === 'top') {
				if (this.inlineMenuBottomActive) {
					this.inlineMenuBottomActive = false;
				}
				this.inlineMenuTopActive = !this.inlineMenuTopActive;
			}
			if (key === 'bottom') {
				if (this.inlineMenuTopActive) {
					this.inlineMenuTopActive = false;
				}
				this.inlineMenuBottomActive = !this.inlineMenuBottomActive;
			}

			this.inlineMenuClick = true;

		},
		changeTheme(theme) {
			this.theme = theme;
			this.changeStyleSheetUrl('theme-css', theme);
		},
		onTopbarThemeChange(theme) {
			this.$emit('topbar-theme', theme);
		},
		onMenuTheme(menuTheme) {
			this.$emit('menu-theme', menuTheme);
		},
		changeStyleSheetUrl(id, value) {
			const element = document.getElementById(id);
			const urlTokens = element.getAttribute('href').split('/');
			urlTokens[urlTokens.length - 2] = value;
			const newURL = urlTokens.join('/');
			this.replaceLink(element, newURL);
		},
		replaceLink(linkElement, href, callback) {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);

				if (callback) {
					callback();
				}
			});
		},
		blockBodyScroll() {
			if (document.body.classList) {
				document.body.classList.add('blocked-scroll');
			} else {
				document.body.className += ' blocked-scroll';
			}
		},
		unblockBodyScroll() {
			if (document.body.classList) {
				document.body.classList.remove('blocked-scroll');
			} else {
				document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
					'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
			}
		},
	},
	computed: {
		layoutContainerClass() {
			return [
				'layout-wrapper',
				'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme,
				{
					'layout-menu-static': this.d_menuMode === 'static',
					'layout-menu-overlay': this.d_menuMode === 'overlay',
					'layout-menu-overlay-active': this.overlayMenuActive,
					'layout-menu-slim': this.d_menuMode === 'slim',
					'layout-menu-horizontal': this.d_menuMode === 'horizontal',
					'layout-menu-active': this.menuActive,
					'layout-menu-mobile-active': this.mobileMenuActive,
					'layout-topbar-mobile-active': this.mobileTopbarActive,
					'layout-rightmenu-active': this.rightPanelActive,
					'layout-rtl': this.$appState.RTL,
					'p-input-filled': this.$primevue.config.inputStyle === 'filled',
					'p-ripple-disabled': this.$primevue.config.ripple === false
				}
			];
		}
	},

	components: {
		'AppTopBar': AppTopBar,
		'AppInlineMenu': AppInlineMenu,
		// 'AppRightPanel': AppRightPanel,
		'AppMenu': AppMenu,
		'AppConfig': AppConfig,
		"AppMobileViewMenu": AppMobileViewMenu,
		// 'AppFooter': AppFooter,
		'AppBreadcrumb': AppBreadcrumb
	}
}
</script>

<style lang="scss">
@import './App.scss';
</style>
