<template>
    <Register v-if="$route.path === '/register'" />
    <Login v-else-if="$route.path === '/login'" />
    <Error v-else-if="$route.path === '/error'" />
    <Access v-else-if="$route.path === '/access'" />
    <ShiftRole v-else-if="$route.path === '/shift-role'" />
    <MobileView v-else-if="$route.path === '/mobile-view'" />
    <NotFound v-else-if="$route.path === '/notfound'" />
    <Landing v-else-if="$route.path === '/landing'" />  
    <App v-else-if="token" :topbarTheme="topbarTheme" :layoutMode="layoutMode" :menuMode="menuMode" :menuTheme="menuTheme" @menu-theme="onMenuTheme" @topbar-theme="onTopbarThemeChange" @layout-mode-change="onLayoutChange" />
 
</template>  
<script>
    import App from './App.vue';
    import Error from './pages/Error';
    import Access from './pages/Access';
    import Login from './pages/Login';
    import Register from './pages/Register';
    import NotFound from './pages/NotFound';
    import Landing from './pages/Landing'; 
    import ShiftRole from './pages/ShiftRole'; 
    import MobileView from './pages/MobileView'; 
    
	export default {
        data() {
            return {
                theme: 'blue',
                layoutMode: 'dark',
                topbarTheme: 'orange',
                menuTheme: 'dark',
                menuMode: 'slim', 
            }
        }, 
        mounted(){
            
            setTimeout( () => { 
                if(!localStorage.getItem('token')){ 
                    if(this.Router.currentRoute.value.name != 'register'){
                        this.$router.push('/login');
                    } 
                }  
            },1000)
            
        },
         
        methods: {
            isMobile(){
                this.showBtnLabels = window.innerWidth <= 991 ? false : true;
            },
            accessDenied(){ 
                alert("accessDenied");
            },
            onLayoutChange(layout) {
                this.layoutMode = layout;
                this.$appState.layoutMode = layout;
                this.menuTheme = layout; 
                
                if (layout === 'dark') {
                    this.topbarTheme = 'dark';
                }
                else {
                    this.topbarTheme = 'blue';
                }
            },
            onTopbarThemeChange(theme) {
                this.topbarTheme = theme.name;

                const themeName = theme.name;
                // const logo = document.getElementById('logo');

                if (themeName == 'white' || themeName == 'yellow' || themeName == 'amber'  || themeName == 'orange' || themeName == 'lime') {
                    // logo.src = 'layout/images/logo-dark.svg';
                }
                else {
                    // logo.src = 'layout/images/logo-light.svg';
                }
            },
            onMenuTheme(menuTheme) {
                this.menuTheme = menuTheme.name;
            }
        },
        components: { 
            "App": App,
            "Error": Error,
            "Access": Access,
            "Login": Login,
            "Register": Register,
            "NotFound": NotFound,
            "Landing": Landing, 
            "ShiftRole":ShiftRole,
            "MobileView" : MobileView
        }
	}
</script>

<style scoped>
</style>
