<template>
	<Toast />
    <ConfirmPopup></ConfirmPopup>
	<div class="layout-topbar shadow-4">
		<div class="layout-topbar-left">
			<router-link class="layout-topbar-logo" to="/"> 
			<div><h3 class="logo-text">{{user.company_name}}</h3></div>
				<!-- <img v-if="user.company_logo" id="logo" :src="filesUrl+'companies/'+user.company_logo"  :alt="user.company_name" style="height: 3.50rem"> -->
				<!-- <img v-else id="logo" src="layout/images/w360-logo.svg" alt="Company Logo" style="height: 3.50rem"> -->
			</router-link> 
			<a  class="layout-menu-button shadow-6 p-ripple" :class="$appState.layoutStyle == 'mobile' ? 'app-view-display' : ''" @click="onMenuButtonClick($event)" v-ripple>
				<i class="pi pi-chevron-right"></i>
			</a> 
			<a  class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
				<i class="pi pi-ellipsis-v fs-large"></i>
			</a>
		</div> 
		<div class="layout-topbar-right" :class="{'layout-topbar-mobile-active': mobileTopbarActive}">
			<div class="layout-topbar-actions-left" v-if="Object.keys(quickLinks).length">
				<!-- <MegaMenu :model="quickLinks" class="layout-megamenu"></MegaMenu> -->
			</div> 
			<div class="layout-topbar-actions-right">
				<ul class="layout-topbar-items"> 
					<li class="layout-topbar-item layout-search-item" v-if="$appState.layoutStyle == 'mobile'">
						<router-link to="/mobile-view">
							<a class="layout-topbar-action rounded-circle p-ripple" v-ripple>
								<i class="pi pi-home fs-large"></i>
							</a> 
						</router-link> 
					</li> 
					<li class="layout-topbar-item layout-search-item" v-if="userAccessControles.userAllRolesNames.length > 1">
						<router-link to="/shift-role">
							<a class="layout-topbar-action rounded-circle p-ripple" v-ripple> 
								<i class="pi pi-sync fs-large"></i>
							</a> 
						</router-link> 
					</li> 
					<li class="layout-topbar-item notifications">
						<a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'notifications');readUserNotifications()" v-ripple>
							<span class="p-overlay-badge">
								<i class="pi pi-bell fs-large"></i>
								<span v-if="newNotifications > 0" id="pv_id_2_badge" class="p-badge p-component p-badge-warning p-badge-dot"></span>
							</span>
						</a> 
						<transition name="layout-topbar-action-panel">
							<ul class="layout-topbar-action-panel notification-top-panel shadow-6" v-show="activeTopbarItem === 'notifications'">
								<li class="mb-3" v-if="newNotifications > 0">
									<span class="px-3 fs-small">You have <b>{{newNotifications}}</b> new notifications</span>
								</li>
								<span v-if="Object.keys(notifications).length"> 
									<li v-for="(notification,key) in notifications" :key="key" class=" " >
										<div class="layout-topbar-action-item flex flex-row align-items-center" :class="notification.is_read < 1 ? 'new-notification' : ''">
											<img v-if="notification.profile_pic" :alt="'pic'" :src="filesUrl+'users/'+notification.profile_pic" />
                            				<img v-if="!notification.profile_pic" :alt="'pic'" :src="filesUrl+'defaults/default.png'" />
											 
											<div class="flex flex-column" :class="{'ml-3': !isRTL, 'mr-3': isRTL}" style="flex-grow: 1;">
												<div class="flex align-items-center justify-content-between mb-1">
													<span class="fs-small font-bold">{{notification.added_by_name}}</span>
													<small>{{moment(notification.notfication_time).fromNow() }}</small>
												</div>
												<span class="fs-small">{{notification.message}}</span>
											</div>
										</div>
									</li>
								</span> 
							</ul>
						</transition>
					</li> 
					<li class="layout-topbar-item app">
						<a class="layout-topbar-action rounded-circle p-ripple" @click="onTopbarItemClick($event, 'apps')" v-ripple>
							<i class="pi pi-table fs-large"></i>
						</a> 
						<transition name="layout-topbar-action-panel">
							<div class="grid grid-nogutter layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'apps'">	
								<div  class="layout-topbar-action-item col-4">
									<router-link to="/">
										<span class="flex align-items-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-palette action indigo-bgcolor white-color"></i>
											<span>Dashboard</span>
										</span>
									</router-link>
								</div>
								<div  v-if="checkPermission(5,'view')"   class="layout-topbar-action-item col-4">
									<router-link to="/access/manage-users">
										<span class="flex align-items-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-users action orange-bgcolor white-color"></i>
											<span>Users</span>
										</span>
									</router-link>
								</div>
								<div   v-if="checkPermission(50,'view')"  class="layout-topbar-action-item col-4">
									<router-link to="/calendar">
										<span class="flex align-items-center justify-content-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-calendar action cyan-bgcolor white-color"></i>
											<span>Calendar</span>
										</span>
									</router-link>
								</div>
								<div   v-if="checkPermission(40,'view')"   class="layout-topbar-action-item col-4">
									<router-link to="/orders/manage-orders"> 
										<span class="flex align-items-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-box action teal-bgcolor white-color"></i>
											<span>Orders</span>
										</span>
									</router-link>
								</div>
								<div  v-if="checkPermission(38,'view')"   class="layout-topbar-action-item col-4">
									<router-link to="/vehicles/manage-vehicles"> 
										<span class="flex align-items-center flex-column text-color p-ripple" v-ripple> 
											<i class="pi pi-car action pink-bgcolor white-color"></i>
											<span>Vehicles</span>
										</span>
									</router-link>
								</div>
								<!-- <div  v-if="permissions[31].includes('view')"   class="layout-topbar-action-item col-4">
									<router-link to="/customers/manage-customers"> 
										<span class="flex align-items-center flex-column text-color p-ripple" v-ripple>
											<i class="pi pi-users action bluegrey-bgcolor white-color"></i>
											<span>Customers</span>
										</span>
									</router-link>
								</div>  -->
							</div>
						</transition>
					</li> 
					<li class="layout-topbar-item">
						<a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple" @click="onTopbarItemClick($event, 'profile')" v-ripple>
							<!-- <img src="demo/images/avatar/amyelsner.png" alt="avatar" style="width: 32px; height: 32px;"> -->
							<img  v-if="user.profile_pic" :alt="user.name" :src="filesUrl+'users/'+user.profile_pic" style="width: 32px; height: 32px;">
        					<img  v-else :alt="user.name" :src="filesUrl+'defaults/default.png'" style="width: 32px; height: 32px;">
						</a> 
						<transition name="layout-topbar-action-panel">
							<ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'profile'">
								<li class="layout-topbar-action-item topbar-user-profile">
									<router-link class="flex flex-row align-items-center p-ripple" to="/settings"> 
										<div class="flex flex-row align-items-center">
											<img  v-if="user.profile_pic" :alt="user.name" :src="filesUrl+'users/'+user.profile_pic"  >
											<img  v-else  :alt="user.name" :src="filesUrl+'defaults/default.png'"  >
											<span class="flex flex-column" :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
												<span v-if="user.name" class="font-bold user-name">{{user.name}}</span>
												<small class="user-email" v-if="user.email" >{{user.email}}</small>
												<Tag><small  v-if="user.designation" >{{user.designation}} </small> ({{userCurrentRole}})</Tag> 
											</span>
										</div>
								 	</router-link>
								</li>
								<li class="layout-topbar-action-item">
									<router-link class="flex flex-row align-items-center p-ripple" to="/settings"> 
										<i class="pi pi-cog pi-fw" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Settings</span>
								 	</router-link>
								</li>
								<li class="layout-topbar-action-item">
									<router-link class="flex flex-row align-items-center p-ripple" to="/shift-role"> 
										<i class="fa fa-shuffle" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Shift Role</span>
								 	</router-link>
								</li> 
								<li class="layout-topbar-action-item">
									<router-link class="flex flex-row align-items-center p-ripple" to="/logout"> 
										<i class="pi pi-power-off" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Logout</span>
								 	</router-link>
								</li> 
							</ul>
						</transition>
					</li>  
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle'],
		data() {
			return {
				searchText: '', 
				user:'',
				QuicklinksLoading:false,
				loadingUserNotifications:false,
				newNotifications:0,
				total_notifications:0,
				notifications: [],
				errorCounter:0,
				userCurrentRole:'',
				quickLinks: [{
						label: 'Quick Links',
						items: [],
						// [[
						// 	{
						// 		label: '',
						// 		items: [
						// 			// { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
						// 			// { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
						// 			// { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/floatlabel' },
						// 			// { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
						// 			// { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' }
						// 		]
						// 	}
						// ]]
				}],
			}
		},
		props: {
			horizontal: {
				type: Boolean,
				default: false
			},
			topbarMenuActive: {
				type: Boolean,
				default: false
			},
			activeTopbarItem: String,
			mobileTopbarActive: Boolean,
			searchActive: Boolean
		},
		mounted(){ 
			this.userCurrentRole = localStorage.getItem('sessionRole'); 
            this.user = JSON.parse(localStorage.getItem('user'));   
			this.getUserNotifications();
			this.getQuickLinks();
			// console.log(this.user);
        },
		methods: {
			getQuickLinks(){ 
				return true;
                // var Vthis = this;
                // this.QuicklinksLoading = true;
                // this.axios.post('getAllQuickLinks')
                // .then( (response) => {  
                //     // this.quickLinks = response.data;  
				// 	let responseStatus = this.printResponseResult(response,false); 
                //     if(responseStatus == 200){ 
				// 		if(response.data){
				// 			response.data.forEach( (rec) => { 
				// 				Vthis.quickLinks[0].items[0][0].items.push({ 'label': rec.name, 'icon': 'pi pi-fw pi-link', 'to': rec.link  }); 
				// 			})
				// 		} 
                //     	this.QuicklinksLoading = false; 
				// 	}
                // })
                // .catch((error) => {    
				// 	this.printResponseResult(error);
                // })
                // .finally(() => { 
                // })  
            },
			getUserNotifications(read=false,freshFetch=false){ 
                var Vthis = this;
                this.loadingUserNotifications = true; 
				if(Object.keys(this.notifications).length < 1){
					freshFetch = true;
				}
                this.axios.post('getUserNotifications',{total_notifications:this.total_notifications,freshFetch:freshFetch})
                .then( (response) => {   
					// let responseStatus = this.printResponseResult(response,false); 
                    if(response){ 
						if(response.data){ 
							if(this.newNotifications < response.data.total_new_notifications){
								var audio = new Audio('https://upload.wikimedia.org/wikipedia/commons/3/34/Sound_Effect_-_Door_Bell.ogg');
								audio.play();
							}
							this.newNotifications = response.data.total_new_notifications;
							this.notifications = response.data.notifications; 
							this.total_notifications = response.data.total_notifications; 
							

							if(this.newNotifications > 0 && read){
								Vthis.readUserNotifications();  
							} 
						}  
					}
                })
                .catch((error) => {    
					this.printResponseResult(error);
					this.errorCounter += 1;
                })
                .finally(() => { 
					this.loadingUserNotifications = false; 
					setTimeout( () => { 
						if(this.errorCounter < 3){
							Vthis.getUserNotifications(); 
						}
						else{
							setTimeout( () => {
								Vthis.errorCounter = 0;
							},100000)
						}
					},1000)   
                })  
            },
			readUserNotifications(){ 
                var Vthis = this; 
                this.axios.post('readUserNotifications')
                .then( (response) => {   
					this.printResponseResult(response,false);  
					this.getUserNotifications(false,true);
                })	
                .catch((error) => {    
                    this.printResponseResult(error);  
                })
                .finally(() => { 
                })  
            },
			onSearchContainerClick(event) {
				this.$emit("search-click", event);
			},
			changeSearchActive(event) {
				this.$emit('search-toggle', event);
			},
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			onTopbarItemClick(event, item) {
				if(item === 'search') {
					this.$emit('search-toggle', event);
				}

				this.$emit('topbaritem-click', {originalEvent: event, item: item});
			},
			onTopbarMobileButtonClick(event) {
				this.$emit('topbar-mobileactive', event);
			},
			onRightPanelButtonClick(event) {
				this.$emit('rightpanel-button-click', event);
			},
			onSearchKeydown(event) { 
				if (event.keyCode == 13) {
					this.$emit('search-toggle', event);
				}
			},
			onEnter() {
				if (this.$refs.searchInput) {
					this.$refs.searchInput.$el.focus();
				}
			}
		},
		computed: {
			topbarItemsClass() {
				return ['topbar-items animated fadeInDown', {
					'topbar-items-visible': this.topbarMenuActive
				}];
			},
			isRTL() {
				return this.$appState.RTL;
			}
		}
	}
</script>
