<template>
    <Toast />
    <Button @click="AppsViewActive = true" icon="pi pi-home" v-if="!AppsViewActive" class="p-button-success layout-config-button mobile-app-view-btn "></Button>
    <Sidebar v-model:visible="AppsViewActive" :position="isRTL ? 'left' : 'right'" :dismissable="true"  :showCloseIcon="true"
		:baseZIndex="1000" class="layout-config p-sidebar-sm fs-small p-0">
        <div class="pages-body login-page flex flex-column mobile-view-page mobile-app-view-sidebar" style="justify-content: center; min-height:98vh; "> 
            <div class="layout-config-panel flex flex-column"> 
                <div class="layout-config-options p-0"> 
                    <div class="col-12 md:col-12 lg:col-12 p-0"> 
                        <div class="card overview-box flex flex-column pt-2 custom-card-container p-0"> 
                            <div class="flex justify-content-between  flex-wrap "> 
                                <ul style="">
                                    <li :style="'background-color:'+  $appState.sideBarColor "  v-for="(module,key) in modulesOrder" :key="key">
                                        <router-link :to="module.route">
                                            <img :src="module.img" />
                                            <h4>{{module.name}}</h4> 
                                        </router-link>
                                    </li>
                                </ul> 
                            </div>  
                        </div>  
                    </div>
                </div>

            </div>
        </div>    
    </Sidebar>
</template>
 
<script> 
export default {
    
    data() {
        return {
            AppsViewActive: false, 
            userRoles:[],
            modulesOrder:[],
        }
    },
    mounted(){ 
        var modules = [];  
		// console.log(this.permissions); 
		if(this.permissions){ 
			modules = Object.keys(this.permissions); 
			// console.log(modules);
			modules.forEach( (moduleid ) => {
                if( !(this.permissions[moduleid].length < 3 && this.permissions[moduleid][1] == "dropdown")){ 
                    let moduleObj = {moduleId : parseInt(moduleid), order : this.permissions[moduleid][0].order};
                    moduleid = parseInt(moduleid);

                    switch (moduleid) {
                        case 2:
                            moduleObj.name = 'Module';
                            moduleObj.route = '/access/modules';
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios-filled/50/null/module.png';

                            break;
                        case 3:
                            moduleObj.name = 'Modules Permissions';
                            moduleObj.route = '/access/module-permissions';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/question-shield.png';

                            break;
                        case 4:
                            moduleObj.name = 'Roles';
                            moduleObj.route = '/access/roles';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios-filled/50/null/who.png';

                            break;
                        case 5:
                            moduleObj.name = 'Users';
                            moduleObj.route = '/access/manage-users';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/groups.png'; //'https://img.icons8.com/dotty/80/null/add-user-group-man-woman.png';

                            break;
                        // case 6:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 7:
                            moduleObj.name = 'Subsidiaries';
                            moduleObj.route = '/companies/manage-subsidiaries';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/related-companies.png';

                            break;
                        case 8:
                            moduleObj.name = 'Branches';
                            moduleObj.route = '/companies/manage-branches';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/active-directory.png';

                            break;
                        case 9:
                            moduleObj.name = 'Departments';
                            moduleObj.route = '/companies/manage-departments';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/dotty/80/null/department.png';

                            break;
                        // case 10:
                        //                         moduleObj.route =  
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 11:
                            moduleObj.name = 'Designations';
                            moduleObj.route = '/companies/manage-designations';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/external-glyph-geotatah/64/null/external-designation-estate-planning-glyph-glyph-geotatah.png';

                            break;
                        case 12:
                            moduleObj.name = 'Access Levels';
                            moduleObj.route = '/access/levels';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios-filled/50/null/unlock--v2.png';

                            break;
                        // case 13:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 14:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 15:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 16:
                            moduleObj.name = 'Companies';
                            moduleObj.route = '/companies/manage-companies';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/group-of-companies.png';

                            break;
                        // case 17:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 18:
                            moduleObj.name = 'Canceled Reasons';
                            moduleObj.route = '/companies/manage-canceled-reasons';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/cancel.png';

                            break;
                        case 19:
                            moduleObj.name = 'Journey Types';
                            moduleObj.route = '/companies/manage-journey-types';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/external-tulpahn-detailed-outline-tulpahn/50/null/external-journey-digital-nomad-tulpahn-detailed-outline-tulpahn.png';

                            break;
                        // case 20:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 21:
                            moduleObj.name = 'Vehicle Type Groups';
                            moduleObj.route = '/vehicles/manage-vehicle-type-groups';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/dotty/50/null/hover-car.png';

                            break;
                        case 22:
                            moduleObj.name = 'Vehicle Tax Rates';
                            moduleObj.route = '/vehicles/manage-vehicle-tax-rates';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/dotty/80/null/tax.png';

                            break;
                        case 23:
                            moduleObj.name = 'Cargo Groups';
                            moduleObj.route = '/vehicles/manage-vehicle-cargo-groups';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios-glyphs/30/null/boxes.png';

                            break;
                        case 24:
                            moduleObj.name = 'Cargo Manifests';
                            moduleObj.route = '/vehicles/manage-vehicle-cargo-manifests';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/dotty/80/null/shipping-container.png';

                            break;
                        case 25:
                            moduleObj.name = 'Cargo Types';
                            moduleObj.route = '/vehicles/manage-vehicle-cargo-types';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/product-loading.png';

                            break;
                        // case 26:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 27:
                            moduleObj.name = 'Products';
                            moduleObj.route = '/products/manage-products';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/external-outline-lafs/50/null/external-products-cloud-storage-outline-lafs.png';

                            break;
                        case 28:
                            moduleObj.name = 'Product Categories';
                            moduleObj.route = '/products/manage-product-categories';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/grocery-shelf.png';

                            break;
                        // case 29:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 30:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 31:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 32:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 33:
                            moduleObj.name = 'Supplier Categories';
                            moduleObj.route = '/suppliers/manage-supplier-categories';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/external-outline-geotatah/64/null/external-supplier-reverse-logistics-outline-geotatah.png';

                            break;
                        case 34:
                            moduleObj.name = 'Document Types';
                            moduleObj.route = '/suppliers/manage-supplier-document-types';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/add-rule.png';

                            break;
                        case 35:
                            moduleObj.name = 'Suppliers';
                            moduleObj.route = '/suppliers/manage-suppliers';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/external-phatplus-solid-phatplus/50/null/external-supplier-consumer-behaviour-phatplus-solid-phatplus.png';

                            break;
                        case 36:
                            moduleObj.name = 'Vehicle Types';
                            moduleObj.route = '/vehicles/manage-vehicle-types';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/dotty/80/null/8x8-vehicle.png';

                            break;
                        // case 37:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 38:
                            moduleObj.name = 'Vehicles';
                            moduleObj.route = '/vehicles/manage-vehicles';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/pastel-glyph/50/null/delivery-lorry.png';

                            break;
                        // case 39:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 40:
                            moduleObj.name = 'Orders';
                            moduleObj.route = '/orders/manage-orders';     
                            moduleObj.icon = '';             
                            moduleObj.img = 'https://img.icons8.com/external-icongeek26-outline-icongeek26/64/null/external-box-logistics-delivery-icongeek26-outline-icongeek26.png';

                            break;
                        // case 41:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 42:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 43:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 44:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 45:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 46:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 47:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 48:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 49:
                            moduleObj.name = 'Dashboard';
                            moduleObj.route = '/dashboard';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/external-flaticons-lineal-flat-icons/50/null/external-dashboard-computer-programming-flaticons-lineal-flat-icons.png';

                            break;
                        case 50:
                            moduleObj.name = 'Calendar';
                            moduleObj.route = '/calendar';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/dotty/80/null/calendar.png';

                            break;
                        // case 51:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 52:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 53:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 54:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 55:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 56:
                            moduleObj.name = 'Automation Settings';
                            moduleObj.route = '/automation-settings';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/window-settings.png';

                            break;
                        case 57:
                            moduleObj.name = 'Email Templates';
                            moduleObj.route = '/email-templates';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/dotty/80/null/send-box.png';

                            break;
                        // case 58:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 59:
                            moduleObj.name = 'My Drive';
                            moduleObj.route = '/my-drive';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/external-smashingstocks-mixed-smashing-stocks/50/null/external-big-data-industrial-production-factory-automation-smart-industries-smashingstocks-mixed-smashing-stocks.png';

                            break;
                        // case 60:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        case 61:
                            moduleObj.name = 'File Categories';
                            moduleObj.route = '/file-categories';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/dotty/80/null/category.png';

                            break;
                        case 62:
                            moduleObj.name = 'Pricing Rules';
                            moduleObj.route = '/pricing-rules';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/estimate.png';

                            break;
                        case 63:
                            moduleObj.name = 'Payment Gateways';
                            moduleObj.route = '/payment-gateways';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/dotty/80/null/transaction-approved.png';

                            break;
                        case 64:
                            moduleObj.name = 'User Categories';
                            moduleObj.route = '/access/manage-users-categories';                  
                            moduleObj.icon = '';
                            moduleObj.img = 'https://img.icons8.com/ios/50/null/category.png';

                            break;
                        // case 65:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;
                        // case 66:
                        //    moduleObj.route = '/access/modules';
                        //    moduleObj.name = 'Modules Permissions'; 
                        //    moduleObj.icon = '';
                        //    moduleObj.img = '';

                        //     break;

                    }
                    if(moduleObj.name){
                        this.modulesOrder.push(moduleObj);
                    }
                }
				
			}); 
		}   

        if(this.userAccessControles.userAllRolesNames.length > 1){
            this.userRoles = this.userAccessControles.userAllRolesNames; 
        }
        else{ 
        }              
    }, 
    methods: {
         
    },
    computed: { 
        isRTL() {
            return this.$appState.RTL;
        }
    }
}
</script>
